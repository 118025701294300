import { useUserSession } from '@/store/userSession'
import { allPermissions, parsePermissions } from '@/store/constants/permission.constants'
import logger from '@/logger'

const permissionDirective = {
  mounted(el, binding) {
    // logger.info(parsePermissions(allPermissions))
    // const { action, resource } = binding.value;
    // logger.info("permission directive", binding.value)
    // logger.info("isAdminRole : ", useUserSession().isAdminRole())
    // if(useUserSession().isAdminRole()) {
    //   return true;
    // }

    el.style.display = 'none';
    // const authStore = useUserSession().hasPermission();
    //
    // if (!authStore.abilities || !authStore.abilities.can(action, resource)) {
    //   el.style.display = 'none';
    // }
  },
};

export default permissionDirective;
